import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { SEO } from "../components/seo";

const Showroom = ({ data }) => {
  const showroom = data.prismicShowroom.data;
  return (
    <div className="md:max-w-[1440px] md:mx-auto mt-5 md:mt-52">
      <SEO
        title="Showroom - Morch"
        description="MØRCH IS A FASHION SALES AGENCY FOUNDED BY LOTTE MØRCH IN 1996 AND IS ONE OF THE LEADING FASHION AGENCIES IN SCANDINAVIA."
      />

      <section className="showroom mx-[17px] md:mx-[34px] flex flex-col-reverse md:flex-row">
        <div className="w-full mx-auto flex flex-col md:flex-row md:items-start md:justify-start">
          <div className="max-w-xl md:max-w-[322px] w-full">
            <div className="content w-full max-w-[322px] text-[8px] sm:text-[10px]">
              <h4 className="uppercase mt-2.5 md:mt-0">
                {showroom.title.text}
              </h4>
              <div className="uppercase whitespace-pre-wrap mt-2 tracking-[-0.1px] leading-[9px]">
                {showroom.address}
              </div>
            </div>

            {/* Mobile Section */}
            <div className="w-full flex md:hidden items-start justify-between mt-5 space-x-5 md:space-x-0">
              <div
                className="image-container w-full max-w-[65%] md:max-w-[290px] md:hidden"
                // onMouseEnter={changeBackground}
                // onMouseLeave={resetCursor}
              >
                <GatsbyImage
                  image={showroom.image.gatsbyImageData}
                  alt={showroom.title.text}
                  className="w-full backdrop h-full"
                  objectFit="contain"
                />
              </div>
              <div className="max-w-[35%] md:max-w-[182px] w-full mt-14 md:mt-[138px]">
                <GatsbyImage
                  image={showroom.side_image.gatsbyImageData}
                  alt={showroom.image.alt}
                  className="w-full backdrop h-full"
                  objectFit="contain"
                />
              </div>
            </div>
            {/* Desktop Section */}
            <div className="hidden md:block max-w-[182px] w-full md:mt-[142px] ml-auto">
              <GatsbyImage
                image={showroom.side_image.gatsbyImageData}
                alt={showroom.image.alt}
                className="w-full backdrop h-full"
                objectFit="contain"
              />
            </div>
          </div>
          <div
            className="image-container w-full max-w-[290px] md:max-w-[492px] ml-[130px] hidden md:block"
            // onMouseEnter={changeBackground}
            // onMouseLeave={resetCursor}
          >
            <GatsbyImage
              image={showroom.image.gatsbyImageData}
              alt={showroom.title.text}
              className="w-full backdrop h-full"
              objectFit="contain"
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  {
    prismicShowroom {
      data {
        title {
          text
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
        side_image {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          alt
        }
        address
      }
    }
  }
`;

export default Showroom;
